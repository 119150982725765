import { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function AboutMe() {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="main-header">
      <div className="container">
        <div className="offer d-flex">
          <div className="offer__info">
            <div className="offer__overhead">
              Здоровье -
            </div>
            <h1 className="offer__title">
              Самое Главное
            </h1>
            <div className="offer__subtitle">
              Доктор поможет вам почувствовать себя лучше <br /> и наслаждаться каждым днем вашей жизни.
            </div>
            <div>
              <button className="offer__btn parallax-btn btn"
                onClick={handleClickOpen}>Знакомство
              </button>
              <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}>
                <DialogTitle>Знакомство</DialogTitle>
                <DialogContent>
                  <div>
                    <p className="offer__text">
                      Фаталиев Гаджи Булатович, кандидат медицинских наук, сосудистый хирург, флеболог, рентгенэндоваскулярный хирург, врач первой квалификационной категории. Врач отделения сосудистой хирургии №38 ГБУЗ ММНКЦ имени С.П. Боткина Департамента здравоохранения города Москвы.
                    </p>

                    <p className="offer__text">
                      В 2013 году с отличием окончил Дагестанскую государственную медицинскую академию по специальности.
                      С 2013 по 2014 год проходил интернатуру по хирургии в Северо-Западном государственном медицинском университете (г. Санкт-Петербург). С 2014 по 2016 год проходил клиническую ординатуру на кафедре сердечно-сосудистой хирургии РМАПО (г. Москва). В 2018 году прошел первичную переподготовку по специальности «Рентгенэндоваскулярная диагностика и лечение» в Первом Московском государственном медицинском университете им. И.М. Сеченова (г. Москва).
                      В 2024 году защитил диссертацию на соискание ученой степени кандидат медицинских наук в центе имени А.Н. Бакулева.
                    </p>

                    <p className="offer__text">
                      Регулярно выступает с докладами на конференциях.
                      Активно участвует в научной работе, имеет более 25 научных публикаций, в том числе в журналах, рецензируемых ВАК.
                    </p>

                    <p className="offer__text">
                      Награды и грамоты:<br />
                      1. Почетная грамота, приуроченная к 110-летию Боткинской больницы (2021)<br />
                      2. Почетная грамота Постоянного представительства Республики Дагестан при Президенте РФ (2021)<br />
                      3. Благодарность Департамента здравоохранения города Москвы (2021)<br />
                      4. Благодарственное письмо мэра Москвы (2022)<br />
                      5. Благодарность президента РФ (2023)<br />
                    </p>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Закрыть</Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <img src="image/doctor.png" alt="" className="doctor-img" />
        </div>
      </div>
    </div>
  );
}
