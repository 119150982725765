export default function Footer() {
  return (
    <footer className="footer" id="footer">
      <div className="container">
        <div className="info-footer d-flex justify-content-between">
          <div className="info-footer__line"></div>
          <div className="info-footer__line_bottom"></div>
          <div className="info-footer__item">
            <div className="info-footer__title">
              Главный Офис
            </div>
            <div className="info-footer__text info-footer__text_short">
              2-й Боткинский проезд дом 5,
              22 корпус
            </div>
            <div className="info-footer__text info-footer__text_short">
              Отделение сосудистой хирургии N38
            </div>
            <a href="#" className="info-footer__link">
              fataliev.gb@mail.ru
            </a>
            {/* <a href="#" className="info-footer__number">
              8(929) 86-86-071
            </a> */}
            <div className="footer-days">
              {/* <div class="info-footer__text">
                Пн - Вт — 8:00 - 18:00
              </div>
              <div class="info-footer__text">
                Сб — 8:00 - 18:00
              </div>
              <div class="info-footer__text">
                Вс — 8:00 - 18:00
              </div> */}
            </div>
          </div>
          <div className="info-footer__wrap">
            {/* <div class="info-footer__item">
              <div class="info-footer__title">
                Последние записи
              </div>
              <div class="info-footer__indent">
                <div class="info-footer__text">
                  тест
                </div>
                <div class="info-footer__text">
                  Июль 10, 2021
                </div>
              </div>
              <div class="info-footer__indent">
                <div class="info-footer__text">
                  Операция на сердце
                </div>
                <div class="info-footer__text">
                  Июль 28, 2021
                </div>
              </div>
              <div class="info-footer__indent">
                <div class="info-footer__text">
                  Коронарное шунтирование
                </div>
                <div class="info-footer__text">
                  Август 11, 2021
                </div>
              </div>
            </div> */}
            <div className="info-footer__item info-footer__item_last">
              <div className="info-footer__title">
                Быстрые ссылки
              </div>
              <a href="#header" className="info-footer__text_indent">
                Главная
              </a>
              <a href="#services" className="info-footer__text_indent">
                Услуги
              </a>
              <a href="#info" className="info-footer__text_indent">
                Квалификация
              </a>
              <a href="#testimonials" className="info-footer__text_indent">
                Отзывы
              </a>
              <a href="#map" className="info-footer__text_indent">
                Карта
              </a>
              <a href="#data" className="info-footer__text_indent">
                Записаться
              </a>
            </div>
          </div>

        </div>
      </div>
    </footer>
  );
}
