import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';

export default function Header() {
  return (
    <header className="header" id="header">
      <div className="top-wrapper">
        <div className="container">
          <div className="top-info d-flex">
            <div className="top-info__wrap">
              {/* <a href="tel:+79298686071" type="tel" className="top-info__telephone">
                <i class="fa fa-phone">+7 (929) 868-60-71</i>
              </a> */}
              <a href="mailto:dr_fataliev@mail.ru" className="top-info__email">
                <i className="fa fa-envelope-o"></i>
                fataliev.gb@mail.ru
              </a>
              <a href='#map_block' className="top-info__map">
                <i className="fa fa-map-o"></i>
                2-й Боткинский проезд дом 5,
                22 корпус
              </a>
              <a href="https://orcid.org/0000-0003-2453-0703" target="_blank" className="top-info__email">
                <i className="fa fa-certificate"></i>
                ORCID
              </a>
              <a href="https://www.elibrary.ru/author_profile.asp?authorid=991588" target="_blank" className="top-info__email">
                <i className="fa fa-certificate"></i>
                SCINCE
              </a>
            </div>
            <div className="top-info__wrap_social d-flex">

              <div className="top-info__social">
                <a
                  href='https://www.instagram.com/dr.fataliev'>
                  <InstagramIcon sx={{ mr: 1.5 }} color="while" />
                </a>
                {/* <a
                  href='https://api.whatsapp.com/send/?phone=79266801577&text=Здравствуйте%2C+у+меня+есть+вопрос'>
                  <WhatsAppIcon sx={{ mr: 1.5 }} color="while" />
                </a> */}

                <a
                  href='https://t.me/79266801577'>
                  <TelegramIcon sx={{ mr: 1.5 }} color="while" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
