export default function SectionOne() {
    return (
        <section className="services" id="services">
            <div className="container">
                <div className="service-wrapper d-flex justify-content-between">
                    <div className="service-list d-flex justify-content-between">
                        <div className="service-list__item">
                            <img src="image/consultation.png" alt="Diagnosis" />
                            <h4 className="service-list__title">
                                Консультация
                            </h4>
                            <div className="service-list__text">
                                от профессионала
                            </div>
                        </div>
                        <div className="service-list__item">
                            <img src="image/diagnosis.png" alt="Diagnosis" />
                            <h4 className="service-list__title">
                                Диагностика
                            </h4>
                            <div className="service-list__text">
                                точный подход
                            </div>
                        </div>
                        <div className="service-list__item">
                            <img src="image/treatment.png" alt="Diagnosis" />
                            <h4 className="service-list__title">
                                Лечение
                            </h4>
                            <div className="service-list__text">
                                индивидуальный подход
                            </div>
                        </div>
                        {/* <div className="service-list__item">
                            <img src="image/laboratory-research.png" alt="Diagnosis" />
                            <h4 className="service-list__title">
                                Лабораторные <span>исследования</span>
                            </h4>
                            <div className="service-list__text">
                                в одной из лучших лабораторий
                            </div>
                        </div> */}
                        <div className="service-list__item">
                            <img src="image/operations.png" alt="Diagnosis" />
                            <h4 className="service-list__title">
                                Операции
                            </h4>
                            <div className="service-list__text">
                                в самых лучших условиях
                            </div>
                        </div>
                    </div>
                    <form className="form" id="popupResult">
                        <div className="form__title">
                            Вопрос доктору
                        </div>
                        <input type="text" name="name" required placeholder="Введите имя"
                            className="form__input"></input>
                        <input type="text" name="email" required placeholder="Введите Email"
                            className="form__input"></input>
                        <textarea type="text" rows="10" cols="67" name="name" required placeholder="Сообщение"
                            className="form__textarea"></textarea>
                        <button className="form__button" data-submit>
                            Отправить
                        </button>
                        <img src="image/user.png" alt="User" className="form__user" />
                        <img src="image/email.png" alt="User" className="form__email" />
                    </form>
                </div>
            </div>
        </section>
    );
}
